import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/CssForAll.css';

export const Contact = () => {
  const form = useRef();
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const [errorMessage, setErrorMessage] = useState(''); // State for error message

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_40qprb1', 'template_vu976t7', form.current, {
        publicKey: 'naXwa-ZjvwWh1Fr7k',
      })
      .then(
        () => {
          setSuccessMessage('Submitted successfully!'); // Set success message
          setErrorMessage(''); // Clear any previous error message
          // Clear the form fields
          form.current.reset();
          // Hide the success message after 5 seconds
          setTimeout(() => {
            setSuccessMessage('');
          }, 5000);
        },
        (error) => {
          setErrorMessage('Failed to send. Please try again.'); // Set error message
          setSuccessMessage(''); // Clear any previous success message
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className="container m-5 bg-dark text-warning p-3">
      <h1 className="text-center">Contact Us</h1>
      {successMessage && (
        <div className="alert alert-success text-center" role="alert">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger text-center" role="alert">
          {errorMessage}
        </div>
      )}
      <form ref={form} onSubmit={sendEmail}>
        <div className="container">
          <div className="row">
            <div className="col">
              <label className="form-label">Name</label>
              <input className="form-control bg-dark text-warning border-warning" type="text" name="name" placeholder="Enter your name" required />
            </div>
            <div className="col">
              <label className="form-label">Email</label>
              <input className="form-control bg-dark text-warning border-warning" type="email" name="email" required />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <label className="form-label">Subject</label>
              <input className="form-control bg-dark text-warning border-warning" type="text" name="subject" required />
              <label className="form-label">Message</label>
              <textarea className="form-control bg-dark text-warning border-warning" name="message" required />
            </div>
          </div>
        </div>
        <input className="btn btn-warning px-5 mt-2 mx-auto d-block" type="submit" value="Send" />
      </form>
    </div>
  );
};
