// App.js

import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from './components/Navbar';
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import './styles/CssForAll.css'; // Use this if it's in a 'styles' folder inside 'src'
import AboutPage from './components/AboutPage';
import CoursesPage from './components/CoursesPage';
import { Contact } from './components/Contact';


function App() {
     return(
          <div style={{ backgroundColor: 'black', color: 'white'}}>

<BrowserRouter>

<NavBar />
<Routes>
  <Route path='/' element={<HomePage/>}/>
  <Route path='/about' element={<AboutPage />}/>
  <Route path='/coursespage' element={<CoursesPage />}/>
<Route path='/contact' element={<Contact/>}/>
</Routes>
<Footer />
</BrowserRouter>

</div>
     )
  
}

export default App;
