// Courses.jsx
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { FaPython, FaJsSquare, FaLanguage, FaLaptopCode, FaJava, FaHtml5, FaChalkboardTeacher, FaClipboardList, FaSalesforce } from 'react-icons/fa';
import '../styles/CssForAll.css';
import { FaChartLine } from 'react-icons/fa6';
const Courses = () => {
  const currentCourses = [
    {
      icon: <FaHtml5 size={50} />,
      title: 'HTML, CSS, BOOTSTRAP',
      description: 'Master HTML, BOOTSTRAP for building modern web applications.',
    },
    {
      icon: <FaJsSquare size={50} />,
      title: 'Frontend Development',
      description: 'Master HTML, CSS, JavaScript, and React for building modern web applications.',
    },
    {
        icon: <FaLanguage size={50} />,
        title: 'English Language - Level 1',
        description: 'Build foundational English skills for everyday communication and interactions.',
      },
      {
        icon: <FaLanguage size={50} />,
        title: 'English Language - Level 2',
        description: 'Advance your English fluency with intermediate-level speaking and comprehension.',
      },

  ];

  const upcomingCourses = [
    {
        icon: <FaJava size={50} />,
        title: 'Java Full Stack Development',
        description: 'Gain proficiency in Java and backend technologies to become a full-stack developer.',
      },
    {
      icon: <FaPython size={50} />,
      title: 'Python Full Stack Development',
      description: 'Learn Python, Django, and more for a complete full-stack experience.',
    },
    {
        icon: <FaLaptopCode size={50} />,
        title: 'Digital Marketing',
        description: 'Learn the essentials of digital marketing and social media strategy.',
      },
      {
        icon: <FaChalkboardTeacher size={50} />,
        title: 'Spanish Language - Beginner',
        description: 'Start your Spanish learning journey with basics in speaking, reading, and writing.',
      },
      {
        icon: <FaChartLine size={50} />,
        title: 'Trading Basics',
        description: 'Learn the essentials of trading, from market analysis to risk management strategies.',
      },
      {
        icon: <FaClipboardList size={50} />,
        title: 'Medical Coding',
        description: 'Get trained in medical coding principles and techniques for a healthcare career.',
      },
      {
        icon: <FaSalesforce size={50} />,
        title: 'Salesforce Development',
        description: 'Learn Salesforce development, CRM, and cloud-based solutions for enterprise applications.',
      },
  ];
  return (
    <Container className="my-5" id="courses">
      <h2 className="text-center text-gold mb-4">Our Courses</h2>
      <Row className="g-4">
        {currentCourses.map((course, index) => (
          <Col md={4} key={index}>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Card className="bg-dark text-gold text-center course-card" style={{ height: 'auto' }}>
                <Card.Body>
                  <div className="mb-3">{course.icon}</div>
                  <Card.Title>{course.title}</Card.Title>
                  <Card.Text>{course.description}</Card.Text>
                </Card.Body>
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>
      <h2 className="text-center text-gold mt-5 mb-4">Upcoming Courses</h2>
      <Row className="g-4">
        {upcomingCourses.map((course, index) => (
          <Col md={4} key={index}>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Card className="bg-dark text-gold text-center course-card" style={{ height: 'auto' }}>
                <Card.Body>
                  <div className="mb-3">{course.icon}</div>
                  <Card.Title>{course.title}</Card.Title>
                  <Card.Text>{course.description}</Card.Text>
                </Card.Body>
              </Card>
            </motion.div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Courses;
