import React, { useEffect, useRef, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import ResponsiveCards from './ResponsiveCards';
import Meeting from './Meeting';
import Testimonials from './Testimonials'; // Custom styles
import first from '../assets/impossible.jpg';
import second from '../assets/success.jpg';
import third from '../assets/cup.jpg';


function HomePage() {
  const [index, setIndex] = useState(0);
  const textRefs = useRef([]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    const handleScroll = () => {
      textRefs.current.forEach((textElement) => {
        if (textElement) {
          const rect = textElement.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          if (rect.top < windowHeight && rect.bottom > 0) {
            textElement.classList.add('sticky');
          } else {
            textElement.classList.remove('sticky');
          }
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
        <img
            src={first}
            alt="first slide"
            className="carousel-image img-fluid"
            loading='lazy'
          />
          <Carousel.Caption className='text-light' style={{textShadow:"2px 2px 5px white"}}>
            <h3>Impossible </h3>
            <p>Every great achievement starts with the courage to begin, even when the odds seem against you.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>  
             <img
            src={second}
            alt="second slide"
            className="carousel-image img-fluid"
            loading='lazy'
          />
          <Carousel.Caption  className='text-light' style={{textShadow:"2px 2px 5px white"}}>
            <h3>Possible</h3>
            <p>Believing in yourself is the bridge between "I can't" and "I can."</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <img
            src={third}
            alt="Third slide"
            className="carousel-image img-fluid"
            loading='lazy'
          />
          <Carousel.Caption  className='text-dark' style={{textShadow:"2px 2px 5px black"}}>
            <h3>Success</h3>
            <p>Success is not the destination but the result of refusing to quit in Step 1 and persisting through Step 2.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <Container fluid className="home-bg">
        <Container fluid className="p-5 bg-transparent text-light text-center">
          <Row>
            <Col>
            <div class="type-writer-container">
  <div class="type-writer-text">Welcome to Elite Hub Academy</div>
</div>
    <h5>Empowering Your Future Through Quality Education</h5>
              <p className="animated-text" ref={(el) => (textRefs.current[0] = el)}>
                At Elite Hub Academy, we believe that education is the key to unlocking potential and transforming lives.
                <br />
                Our mission is to provide accessible, high-quality learning experiences that cater to diverse learners.
                <br />
                Whether you’re looking to advance your career, explore new interests, or enhance your skills, we offer a wide range of courses designed to help you achieve your goals.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Meeting />

      <Container fluid className="p-5 my-5 bg-transparent text-light text-center">
        <Row>
          <Col>
            <h1 style={{ color: '#f4a618' }}>Join Us Today!</h1>
            <p className="animated-text" ref={(el) => (textRefs.current[1] = el)}>
              Embark on your educational journey with Elite Hub Academy. Sign up now to unlock exclusive resources, expert guidance, and a community dedicated to your success!
            </p>
          </Col>
        </Row>
      </Container>

      <ResponsiveCards />
      <Testimonials />
    </>
  );
}

export default HomePage;
