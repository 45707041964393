// App.js
import React from 'react';
import Courses from './Courses';


function CoursesPage() {
  return (
    <div>
    
      {/* Courses Section */}
      <Courses />

      {/* Footer */}

    </div>
  );
}

export default CoursesPage;
