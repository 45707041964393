import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
  return (
    <footer className="footer-container">
      {/* Footer Content */}
      <div className="footer-content">
        <div className="container">
          <div className="row">
            {/* EliteHub Info */}
            <div className="col-md-4">
              <h5>About EliteHub Academy</h5>
              <p>
                EliteHub Academy is a premier institution providing cutting-edge training in technology, management, and career-oriented courses. We focus on equipping our students with the skills needed to excel in the competitive job market.
              </p>
            </div>

            {/* Quick Links */}
            <div className="col-md-4">
              <h5>Quick Links</h5>
              <ul className="list-unstyled">
                <li><a href="/coursespage" className="text-light">Courses</a></li>
                <li><a href="/about" className="text-light">About Us</a></li>
                <li><a href="/contact" className="text-light">Contact</a></li>
                {/* <li><a href="#blog" className="text-light">Blog</a></li> */}
              </ul>
            </div>

            {/* Contact Info */}
            <div className="col-md-4">
              <h5>Contact Us</h5>
              <p>Email: contact@elitehubacademy.com</p>
              <p>Phone: 7893417133,6302882025</p>
              <p>Address: Balaji Nagar,puppalaguda,Manikonda,Kv rngareddy,Hyderabad,Telengana</p>

              {/* Social Media */}
              <div className="social-links">
                <a href="https://www.facebook.com/profile.php?id=61568767602191" className="text-light me-3"><i className="bi bi-facebook"></i></a>
                <a href="https://x.com/ElitehubAcademy" className="text-light me-3"><i className="bi bi-twitter"></i></a>
                <a href="https://www.linkedin.com/in/elitehub-academy2024/" className="text-light"><i className="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom text-light text-center ">
        <p className="mb-0">© 2024 EliteHub Academy. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
