import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import img1 from "../assets/younggirltypinglaptophome.jpg";
import img2 from "../assets/onlineclass.jpg";
import img3 from "../assets/presentation.jpg";
// Sample data for the cards
const cardData = [
  {
    id: 1,
    title: 'Personalized Learning',
    text: 'At Elite Hub Academy, we understand that every learner is unique. Our personalized learning approach tailors educational experiences to fit individual needs and goals. With customized study plans and one-on-one mentoring, we empower you to learn at your own pace and achieve your full potential.',
    image: img1,
  },
  {
    id: 2,
    title: 'Interactive Online Sessions',
    text: 'Engage in dynamic online sessions that foster collaboration and interaction. Our experienced instructors use cutting-edge technology to create a lively virtual classroom environment. Participate in discussions, group activities, and hands-on projects that enhance your understanding and retention of the material.',
    image:img2, },
  {
    id: 3,
    title: 'Test Presentation',
    text: 'Master the art of effective presentation with our specialized test preparation courses. Learn essential skills for presenting your ideas clearly and confidently in front of an audience. Our expert coaches provide personalized feedback and strategies to help you excel in any testing or presentation scenario.',
    image:img3,
   },
];

function ResponsiveCards() {
  const textRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      textRefs.current.forEach((textElement) => {
        if (textElement) {
          const rect = textElement.getBoundingClientRect();
          const windowHeight = window.innerHeight;

          // Check if the element is in the viewport
          if (rect.top < windowHeight && rect.bottom > 0) {
            textElement.classList.add('sticky');
          } else {
            textElement.classList.remove('sticky');
          }
        }
      });
    };

    // Listen to the scroll event
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container className="my-5">
      <Row>
        {cardData.map((card, index) => (
          <Col key={card.id} xs={12} sm={6} md={4} className="mb-4">
            <Card style={{ width: '100%' }} className='bg-transparent'>
              <Card.Img variant="top" src={card.image} className='img-fluid w-100' />
              <Card.Body className='text-center'>
                <Card.Title style={{ color: '#f4a618' }}>{card.title}</Card.Title>
                <Card.Text
                  className="animated-text text-light"
                  ref={(el) => (textRefs.current[index] = el)} // Set individual reference for each card text
                >
                  {card.text}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ResponsiveCards;
