import React, { useEffect, useRef } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';

function Testimonials() {
    const textRefs = useRef([]);

    useEffect(() => {
        const handleScroll = () => {
            textRefs.current.forEach((textElement) => {
                if (textElement) {
                    const rect = textElement.getBoundingClientRect();
                    const windowHeight = window.innerHeight;

                    // Check if the element is in the viewport
                    if (rect.top < windowHeight && rect.bottom > 0) {
                        textElement.classList.add('sticky');
                    } else {
                        textElement.classList.remove('sticky');
                    }
                }
            });
        };

        // Listen to the scroll event
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="my-5">
            <h5 className="text-center mb-4" style={{color:'#f4a618'}}>Success stories of our students</h5>
            <Carousel className="text-center p-5 text-light">
                {/* Testimonial 1 */}
                <Carousel.Item className="p-5">
                    <h4 className="text-light">Amazing Learning Experience</h4>
                    <p className="animated-text" ref={(el) => textRefs.current[0] = el}>
                        "The courses at EliteHub Academy have completely transformed my career. The instructors are knowledgeable, and the hands-on projects made learning incredibly effective."
                    </p>
                    <p>- Dinesh Kumar</p>
                </Carousel.Item>

                {/* Testimonial 2 */}
                <Carousel.Item className="p-5">
                    <h4 className="text-light">Highly Recommend This Academy</h4>
                    <p className="animated-text" ref={(el) => textRefs.current[1] = el}>
                        "EliteHub Academy offers the perfect mix of theory and practical application. I was able to apply what I learned to real-world scenarios right away!"
                    </p>
                    <p>- Hari Krishna</p>
                </Carousel.Item>

                {/* Testimonial 3 */}
                <Carousel.Item className="p-5">
                    <h4 className="text-light">A Game Changer for My Career</h4>
                    <p className="animated-text" ref={(el) => textRefs.current[2] = el}>
                        "Thanks to the structured learning and continuous support, I secured a job at a leading tech firm within months of completing the courses."
                    </p>
                    <p>- Kulasekar</p>
                </Carousel.Item>

                {/* Testimonial 4 */}
                <Carousel.Item className="p-5">
                    <h4 className="text-light">Instructors Are Top-Notch</h4>
                    <p className="animated-text" ref={(el) => textRefs.current[3] = el}>
                        "I loved the way the instructors broke down complex concepts into simple, easy-to-understand lessons. I felt supported throughout my learning journey."
                    </p>
                    <p>- Priya Singh</p>
                </Carousel.Item>

                {/* Testimonial 5 */}
                <Carousel.Item className="p-5">
                    <h4 className="text-light">Great for Career Transition</h4>
                    <p className="animated-text" ref={(el) => textRefs.current[4] = el}>
                        "I transitioned from a non-technical field to a software engineering role with confidence, thanks to the well-designed curriculum and career guidance at EliteHub Academy."
                    </p>
                    <p>- Rohan Verma</p>
                </Carousel.Item>
            </Carousel>
        </div>
    );
}

export default Testimonials;
