import React from "react";
import video from "../assets/video.mp4";
const Meeting = () => {
  return (
    <div>
      <video width="100%" controls autoPlay muted>
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

export default Meeting;
