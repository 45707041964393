// AboutPage.jsx
import React, { useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaChalkboardTeacher, FaCalendarAlt, FaPeopleArrows } from 'react-icons/fa';
import { motion } from 'framer-motion';
import '../styles/CssForAll.css'; // Custom CSS for additional styling
import mission from '../assets/mission.png';
import question from '../assets/question.png';
import study from '../assets/study.png';
import expert from '../assets/expert.png'
import access from '../assets/access.png';
const AboutPage = () => {
  const textRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      textRefs.current.forEach((textElement) => {
        if (textElement) {
          const rect = textElement.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          if (rect.top < windowHeight - 50) {
            textElement.classList.add('reveal');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Dynamic data for each section, now with image URLs
  const missionData = [
    {
      icon: <FaChalkboardTeacher />,
      title: "Our Mission",
      description: "Our mission is to make quality education accessible to all. In a rapidly changing world, Elite Hub Academy is here to help you stay ahead with flexible, affordable courses at your own pace.",
image:  mission },
    {
      icon: <FaPeopleArrows />,
      title: "Why Choose Us?",
      description: "Taught by experts, our courses are tailored to fit busy schedules and are accessible for all. Join us and realize your full potential with Elite Hub Academy.",
image:question  },
  ];

  const featureData = [
    {
      icon: <FaChalkboardTeacher />,
      title: "Expert-Led Courses",
      description: "Gain practical skills from industry experts with real-world applications.",
image: expert  },
    {
      icon: <FaCalendarAlt />,
      title: "Flexible Learning",
      description: "Learn at your own pace with on-demand lessons tailored to your schedule.",
image:study    },
    {
      icon: <FaPeopleArrows />,
      title: "Inclusive Access",
      description: "Accessible, affordable options including scholarships and financial aid.",
image:access   },
  ];

  return (
    <Container fluid className="about-page bg-dark text-gold py-5">
      <Container>
      <h2 style={{textAlign:'center'}}>About us</h2>
         {/* Mission Data in two rows */}
         {missionData.map((mission, index) => (
          <Row key={index} className="align-items-center my-4">
            {index % 2 === 0 ? (
              <>
                <Col md={6} ref={(el) => (textRefs.current[index] = el)}>
                  <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                  >
                    <h3>{mission.icon} {mission.title}</h3>
                    <p>{mission.description}</p>
                  </motion.div>
                </Col>
                <Col md={6}>
                  <motion.img
                    src={mission.image}
                    alt={mission.title}
                    className="img-fluid rounded shadow"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    loading='lazy'
                  />
                </Col>
              </>
            ) : (
              <>
                
                <Col md={6}>
                  <motion.img
                    src={mission.image}
                    alt={mission.title}
                    className="img-fluid rounded shadow"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                  />
                </Col>
                <Col md={6} ref={(el) => (textRefs.current[index] = el)}>
                  <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                  >
                    <h3>{mission.icon} {mission.title}</h3>
                    <p>{mission.description}</p>
                  </motion.div>
                </Col>
              </>
            )}
          </Row>
        ))}
         <Container fluid className="d-flex justify-content-center align-items-center">
         <Row>
    <Col xs={12} md={6} lg={4} className="mb-3">
      <strong>Excellence</strong><br />
      We strive for the highest standards in everything we do, providing top-notch education.
    </Col>
    <Col xs={12} md={6} lg={4} className="mb-3">
      <strong>Integrity:</strong><br />
      We are committed to transparency, honesty, and ethical practices in all our endeavors.
    </Col>
    <Col xs={12} md={6} lg={4} className="mb-3">
      <strong>Inclusivity:</strong><br />
      We believe in providing equal access to learning opportunities for all students, regardless of background.
    </Col>
    <Col xs={12} md={6} lg={4} className="mb-3">
      <strong>Innovation:</strong><br />
      We embrace modern technology and new methodologies to enhance the learning experience.
    </Col>
    <Col xs={12} md={6} lg={4} className="mb-3">
      <strong>Community:</strong><br />
      We foster a supportive and collaborative environment, where students and educators can grow together.
    </Col>
    <Col xs={12} md={6} lg={4} className="mb-3">
    <strong>Collaboration:</strong><br />
      We encourage teamwork and partnerships that drive collective growth and success.
    </Col>
  </Row>
    </Container>
        {/* Features Section with images */}
        <Row className="g-4 text-center" xs={1} sm={2} md={3}>
          {featureData.map((feature, index) => (
            <Col key={index} ref={(el) => (textRefs.current[index + missionData.length] = el)}>
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 + index * 0.2 }}
              >
                <img
                  src={feature.image}
                  alt={feature.title}
                  className="img-fluid rounded shadow mb-3"
                  style={{ width: "100%", height: "auto" }}
                />
                <h4>{feature.icon} {feature.title}</h4>
                <p>{feature.description}</p>
              </motion.div>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
};

export default AboutPage;
