import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {  FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaWhatsapp, FaYoutube } from "react-icons/fa";
import logos from '../assets/ElitehubLogo.jpeg';
import '../styles/CssForAll.css';
const handleEnrollClick = () => {
  // Replace with the phone number you want to dial
  window.location.href = 'tel:+91 78934 17133';
}
function NavBar() {
  return (
    <Navbar expand="lg" className="bg-dark">
      <Container>
        <Navbar.Brand href="/">
          <img 
            src={logos} 
            alt="logo" 
            style={{ width: '70px', height: '70px', borderRadius: '50%' }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto text-center">
            <Nav.Link href="/" className="hover" style={{border:'1px groove #f4a618', color: '#f4a618', fontSize: '18px',margin:'10px' ,background:'black',borderRadius:'10px'}}>Home</Nav.Link>
            <Nav.Link href="/about" className="hover" style={{border:'1px groove #f4a618', color: '#f4a618', fontSize: '18px' ,margin:'10px' ,background:'black',borderRadius:'10px'}}>About</Nav.Link>
            <Nav.Link href="/coursespage" className="hover" style={{border:'1px groove #f4a618', color: '#f4a618', fontSize: '18px' ,margin:'10px' ,background:'black',borderRadius:'10px'}}>Courses</Nav.Link>
            <Nav.Link href="/contact" className="hover" style={{border:'1px groove #f4a618', color: '#f4a618', fontSize: '18px' ,margin:'10px' ,background:'black',borderRadius:'10px'}}>Contact</Nav.Link>
          </Nav>
          <Button  onClick={handleEnrollClick} href='#enroll' id="enroll" style={{ background: '#f4a618' }} className="btn p-3">Enroll Now</Button>
          <Nav className="ms-auto">
            <Nav.Link href="https://www.instagram.com/elitehubacademy/">
              <FaInstagram style={{ fontSize: '40px', color: '#f4a618' }} />
            </Nav.Link>
            <Nav.Link href="https://www.linkedin.com/in/elitehub-academy2024/">
              <FaLinkedin style={{ fontSize: '40px', color: '#f4a618' }} />
            </Nav.Link>
            <Nav.Link href="https://x.com/ElitehubAcademy">
              <FaTwitter style={{ fontSize: '40px', color: '#f4a618' }} />
            </Nav.Link>
            <Nav.Link href=" https://whatsapp.com/channel/0029VarjIVJ5vKAEw82iQ33B ">
              <FaWhatsapp style={{ fontSize: '40px', color: '#f4a618' }} />
            </Nav.Link>
            <Nav.Link href="#">
              <FaFacebook style={{ fontSize: '40px', color: '#f4a618' }} />
            </Nav.Link>
            <Nav.Link href="#">
              <FaYoutube  style={{ fontSize: '40px', color: '#f4a618' }} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
